import React from "react";

function ServicesCard({ item }) {
    return (
        <div className=" col-12 col-md-3 mt-4 ">
            <div className="mx-2  py-5  card">
                <img
                    src="https://www.ongraph.com/wp-content/uploads/2022/01/developer.svg"
                    className="col-3 mx-auto"
                    alt="service card"
                />

                <p className="fw-bold lh-sm mt-4" style={{ fontSize: "1.3rem" }}>
                    {item.name}
                </p>

                <p className="  text-start mx-2" style={{ fontSize: "0.9rem" }}>
                    {item.infomation}
                </p>
            </div>
        </div>
    );
}

// export default CustomCard;


export default ServicesCard