import './App.css';
import Homepage from './views/Homepage';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomNavbar from './components/CustomNavbar';
import Footer from './components/Footer';
import { useEffect, useRef } from 'react';
import AlexaAuth from './views/AlexaAuth';
import { Route, Routes } from 'react-router-dom';
import GoogleHomeAuth from './views/GoogleHomeAuth';


function App() {

  const homeRef = useRef(null)
  const servicesRef = useRef(null)
  const howWeWorkRef = useRef(null)
  const aboutRef = useRef(null)
  const contactRef = useRef(null)


  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (

    <Routes>
                        <Route exact path="/" element={ <div className="App bgDark" style={{ overflow: "scroll" }} >
      <CustomNavbar homeRef={homeRef} servicesRef={servicesRef} howWeWorkRef={howWeWorkRef} aboutRef={aboutRef} contactRef={contactRef} scrollToSection={scrollToSection} />
      <Homepage homeRef={homeRef} servicesRef={servicesRef} howWeWorkRef={howWeWorkRef} aboutRef={aboutRef} />
      <Footer contactRef={contactRef} />
    </div>} />
                        <Route
                            path='/alexaauth'
                            element={<AlexaAuth />}
                        />
                        <Route
                            path='/authgooglehome'
                            element={<GoogleHomeAuth />}
                        />
                    </Routes>
   
  );
}

export default App;
