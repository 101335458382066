import React from "react";

const Footer = ({ contactRef }) => {
  return (
    <div className="container-fluid m-0 p-0" ref={contactRef}>
      <div
        className=" container align-items-center flex-wrap d-flex mt-5 mt-md-0 m-0 p-0 mx-auto py-3"
        style={{}}
      >
        <div className=" col-12 col-md-6">
          <p className=" text-light text-md-start fs-6 fw-bold my-3 my-md-0">
            Email : info@algodive.com
          </p>
        </div>
        <div className="col-12 col-md-6  text-md-end mt-3 mt-md-0">
          {/* <i className="fa-brands fa-facebook text-light mx-3 fs-3"></i> */}
          <a href="https://twitter.com/algodive" target="_blank"><i className="fa-brands fa-twitter text-light mx-3 fs-3"></i></a>
           <a href="https://www.linkedin.com/company/algodive-pvt-ltd" target="_blank"><i className="fa-brands fa-linkedin text-light mx-3 fs-3"></i></a>
          {/* <i className="fa-brands fa-instagram text-light mx-3 fs-3"></i> */}
           <a href="https://github.com/algodive" target="_blank"><i className="fa-brands fa-github mx-3 fs-3 text-light"></i></a>
        </div>
      </div>

      <div>
        <p className="text-light pt-4 border-top ">
          Made With
          <i className="fa-solid fa-heart text-danger mx-2"></i>
          By Algodive In India
        </p>
      </div>
    </div>
  );
};

export default Footer;
