import React from "react";

function Card({ image, text }) {
  return (
    <div className="col-6 col-md-3  border-end my-3">
      <div className="mx-1 bg-light py-5">
        <img
          src={image}
          alt='Card'
          className="col-3 mx-auto"
        />
      </div>
      <p className="text-dark" style={{ fontSize: "1.3rem" }}>
        {text}
      </p>
    </div>
  );
}

export default Card;
