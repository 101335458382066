import React from 'react'
import Card from '../components/Card'
import ServicesCard from '../components/ServicesCard'
import '../../src/App.css'
import teamImg from '../assets/team.png'
import developerImg from '../assets/developer.svg'
import applicationImg from '../assets/application.svg'
import userImg from '../assets/user.svg'
import technologyImg from '../assets/technology.svg'
import solutionImg from '../assets/solution.png'
import aboutUsImg from '../assets/About_Us_Image.png'
import agileSdlcImg from '../assets/agile-sdlc.png'

const Homepage = ({ homeRef, servicesRef, howWeWorkRef, aboutRef }) => {
    const servicesCardData = [
        {
            name: 'Mobile App Development',
            img: '',
            infomation: 'We develop mobile application for Android and IOS platform for your Business by using right combination of technologies. We deliver the application which will prove the usability and relevance today and also in near future.'
        },
        {
            name: 'Web App Development',
            img: '',
            infomation: 'In this digital age, Web application is an essential tool to reach and engage with your customer. It allows your customer to access your business from anytime and anywhere. We develop Web App  which has improved user experience and scalable.'
        },
        {
            name: 'Web3 dApps Development',
            img: '',
            infomation: 'Decentralized Apps (dApps) has several advantages over centralized app. dApps are built on blockchain technology, which makes all transactions and operations transparent and publicly accessible. We provide dApps development for your Business need.'
        },
        {
            name: 'AI & ML Based Solution',
            img: '',
            infomation: 'AI and ML based solutions can provide a range of benefits to businesses and organizations. It can automate the tasks and also it can provide personalized recommendation and experiences. We can do that for your Business.'
        },
        {
            name: 'Smart Contract Development',
            img: '',
            infomation: 'Smart contracts are stored on a public blockchain, which means that all parties can view the terms of the contract and the status of the transaction at any time. We can develop smart contract and deploy it on your favorite Blockchain network.'
        },
        {
            name: 'Blockchain Automation',
            img: '',
            infomation: 'Blockchain automation can provide businesses with a range of benefits, including increased efficiency, transparency, security, and trust. We can expect to see even more innovative uses for blockchain automation in various industries.'
        },
        {
            name: 'Blockchain Solutions',
            img: '',
            infomation: 'Blockchain solutions have the potential to transform various industries by providing secure, transparent, and decentralized solutions that improve efficiency, reduce costs, and increase trust. We can provide Blockchain based solution for your Business.'
        },
        {
            name: 'Business Solutions',
            img: '',
            infomation: 'We also provide customised solutions for your Business. We have experienced team of Product Engineers who can recommend you personalized solutions for your Business. Feel free to connect with us with your requirements. We are here for you.'
        }
    ]

    const topCardData =
        [
            {
                image: developerImg,
                text: '20+ Developer'
            },
            {
                image: applicationImg,
                text: '25+ Apps Delivered'
            },
            {
                image: userImg,
                text: '15+ Enterprise Customer'
            },
            {
                image: technologyImg,
                text: '20+ Supported Technologies'
            }
        ]

    return (
        <div className=' container d-flex flex-column  justify-content-center  my-md-5 py-md-5 py-2 my-2 flex-wrap' ref={homeRef} >
            <div style={{}} className=" flex-row  d-flex  justify-content-center flex-wrap">
                <div className='col-12 col-md-7 d-flex d-flex flex-column  justify-content-center'>
                    <h1 className='text-light text-start lh-1 heading' >
                        Simplifying technology,
                        <br></br>
                        empowering growth.
                    </h1>
                    <div className='mt-4'>
                        <h3 className='text-start subHeadingText my-2 my-md-0' style={{ height: '100%', color: '#5fcefa' }}>
                            We deliver the best application backed with latest technology.
                        </h3>
                    </div>
                </div>
                <div className=' col-12 col-md-5 my-4 my-md-0'>
                    <img src={teamImg} alt='Algodive Teamwork' className='img-fluid col-10' />
                </div>
            </div>
            {/* //feature card start */}
            <div className='d-flex container border flex-wrap p-0 bg-light overflow-hidden mt-5 ' style={{ borderRadius: '1rem', }}>
                {
                    topCardData.map((item, i) => {
                        return (
                            <Card key={i} image={item.image} text={item.text} />
                        )
                    })
                }
            </div>
            {/* information div start */}
            <div className='container  w-100 mt-3 mt-md-5 pt-2 pt-md-5 d-flex flex-wrap flex-column-reverse flex-md-row'>
                <div className='col-12 col-md-6 mt-4'>
                    <h1 className='subHeadingText text-light text-start'>
                        WE BUILD POWERFUL, SMART SOLUTIONS
                    </h1>

                    <h1 style={{ color: '#5fcefa' }} className='subHeadingText  text-start '>
                        DELIVER TOP-NOTCH SERVICES
                    </h1>

                    <p className='text-light m-0 p-0 text-start paraText mt-4'>
                        We provide innovative solutions for your digital needs. Our team of experts specializes in web and mobile app development, web3 services, Blockchain Services AI, and ML-based solutions. We aim to provide the best possible experience for our clients by understanding their unique requirements and delivering solutions that exceed their expectations.
                    </p>
                    <p className='text-light m-0 p-0 text-start paraText mt-4'>
                        Our web and mobile app development team is skilled in creating user-friendly, responsive, and visually appealing apps that work seamlessly across all platforms. We also offer web3 services, including smart contract development, decentralized app development, and blockchain consulting. Our AI and ML-based solutions help businesses automate their operations, increase efficiency, and drive growth.
                    </p>
                    <p className='text-light m-0 p-0 text-start paraText mt-4'>
                        We are committed to providing reliable and high-quality services to our clients, helping them achieve their digital goals. Contact us today to learn more about how we can help you with your digital transformation.
                    </p>
                </div>

                <div className=' col-12 col-md-6 my-5 my-md-0 '>
                    <img src={solutionImg} alt='Solutions' className='img-fluid col-12 col-md-11' />
                </div>
            </div>
            {/* Our Services */}

            <div ref={servicesRef}>
                <h1 className='text-light fs-1 fw-semibold my-5'>
                    OUR SERVICES
                </h1>
            </div>
            <div className='d-flex container  col-12 flex-wrap p-0   horizontalScroll '>
                {
                    servicesCardData.map((d, ind) => {
                        return (
                            <ServicesCard key={ind} item={d} />
                        )
                    })
                }
            </div>

            {/* How We Work */}
            <div className=' mt-md-5 pt-md-5 mt-2 pt-3' ref={howWeWorkRef}>
                <h1 className='text-light fs-1 fw-semibold mb-3 mb-md-5 mt-md-0 pb-md-5 pb-0 mt-5'>
                    HOW WE WORK
                </h1>
            </div>
            <div className='container  d-flex  justify-content-center   flex-wrap flex-column-reverse flex-md-row-reverse ' >
                <div className='col-12 col-md-6    ps-0 ps-md-4'>
                    <p className='text-light m-0 p-0 text-start paraText'>
                        At Algodive, we pride ourselves on our streamlined and efficient work process. We believe that a well-structured process is the key to delivering high-quality software solutions to our clients. Here's a brief overview of how we work: <br></br>

                        1. Discovery: The first step in our process is to understand our client's needs and requirements. We start by gathering information about their business and their goals. We then conduct research to get a better understanding of the industry and the competition. <br></br>

                        2. Planning: Once we have a clear understanding of our client's needs, we move on to the planning phase. We create a detailed project plan that outlines the scope, timeline, and budget for the project. We also define the roles and responsibilities of each team member. <br></br>

                        3. Design: In the design phase, we create a mockup of the software solution. This helps us visualize the final product and identify any potential design issues. We work closely with our client to ensure that the design meets their requirements and expectations. <br></br>

                    </p>
                </div>
                <div className=' col-12 col-md-6 my-5 my-md-0 '>
                    <img src={agileSdlcImg} alt='Taskcomet' className='img-fluid col-12 col-md-11' />
                </div>
            </div>
            <div className='container  d-flex  justify-content-center   flex-wrap flex-column-reverse flex-md-row-reverse ' >
            <p className='text-light ps-0 ps-md-4 m-0 p-0 text-start paraText mt-3'>
            4. Development: The development phase is where we start building the software solution. Our development team works in an agile environment, meaning that we break the project down into smaller, manageable tasks. This allows us to deliver incremental updates to our clients and ensure that we're on track with the project timeline. <br></br>

5. Testing: Before we launch the software solution, we conduct thorough testing to identify and fix any bugs or issues. We use a combination of manual and automated testing to ensure that the software is functioning as intended.<br></br>

6. Launch: Once we've completed testing and made any necessary fixes, we launch the software solution. We work closely with our clients during this phase to ensure a smooth transition and provide any necessary training.<br></br>

7. Support: Our work doesn't end with the launch of the software solution. We provide ongoing support to our clients to ensure that their software is running smoothly and efficiently. We're always available to answer any questions or address any concerns that may arise.<br></br><br></br>

At Algodive, we're committed to delivering high-quality software solutions that meet our client's needs and exceed their expectations. Our streamlined work process helps us achieve this goal and ensures that we deliver on time and within budget.
</p>
            </div>

            {/* About Us */}
            <div className=' mt-md-5 pt-md-5 mt-2 pt-3' ref={aboutRef}>
                <h1 className='text-light fs-1 fw-semibold   mt-md-0 pb-md-5 pb-0 mt-5'>
                    ABOUT US
                </h1>
            </div>
            <div className='container  d-flex  justify-content-center   flex-wrap flex-column-reverse flex-md-row-reverse ' >
                <div className='col-12 col-md-6 mt-md-1 mt-2 pt-2  ps-0 ps-md-4'>
                    <p className='text-light m-0 p-0 text-start paraText'>
                        Welcome to Algodive, where we provide top-notch IT services with the latest technology at cost-effective prices. As a software engineer and entrepreneur, I started this company with a mission to provide businesses of all sizes with high-quality IT services that are affordable and customized to their needs.
                    </p>

                    <p className='text-light m-0 p-0 text-start paraText '>
                        At Algodive, we believe in taking a holistic approach to IT services. We understand that technology is not just a tool but a critical component of any successful business. Therefore, we work with our clients to provide end-to-end solutions that cater to their unique needs and challenges.
                    </p>
                    <p className='text-light m-0 p-0 text-start paraText '>
                        Our team of experienced software engineers, designers, and project managers work tirelessly to ensure that our clients receive the best possible service. We pride ourselves on our ability to deliver quality work within tight deadlines, while maintaining clear communication with our clients throughout the entire process.
                    </p>
                    <p className='text-light m-0 p-0 text-start paraText '>
                        At Algodive, we value our clients' satisfaction above all else. That's why we are committed to building long-term relationships with our clients by providing them with the best possible service. We believe that by working together, we can achieve great things. We look forward to working with you and helping your business succeed.
                    </p>
                </div>
                <div className=' col-12 col-md-6 mt-md-1 mt-2 pt-2  ps-0 ps-md-4 bg-white'>
                    <img src={aboutUsImg} alt='About' className='img-fluid col-12 col-md-12' />
                </div>
            </div>
        </div>
    )
}

export default Homepage