import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function CustomNavbar({ homeRef, servicesRef, howWeWorkRef, aboutRef, contactRef, scrollToSection }) {

  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleNavCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };


  return (
    <>
      <Navbar className="bgDark px-2 px-md-5" variant="dark" expand="lg">
        <Navbar.Brand>Algodive</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleNavCollapse} />
        <Navbar.Collapse id="basic-navbar-nav" className={isCollapsed ? "collapse show" : "collapse"}>
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link onClick={() => { scrollToSection(homeRef) }}>Home</Nav.Link>
            <Nav.Link onClick={() => { scrollToSection(servicesRef) }}>Services</Nav.Link>
            <Nav.Link onClick={() => { scrollToSection(howWeWorkRef) }}>How We Work</Nav.Link>
            <Nav.Link onClick={() => { scrollToSection(aboutRef) }}>About</Nav.Link>
            <Nav.Link onClick={() => { scrollToSection(contactRef) }}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default CustomNavbar